import { useState } from "react";

import sendMessageAux from "@/assets/img/send_message_aux.svg";
import sendingImg from "@/assets/img/sending.svg";
import sendingHoverImg from "@/assets/img/sending_hover.svg";
import sendingWritingImg from "@/assets/img/sending_writing.svg";

import theme from "@/branding/theme";

import "./SilboSendButton.css";

export const SilboSendButton = (props) => {
  const [sendHover, setSendHover] = useState(false);
  const sendButton = theme().home.sendButton
    ? theme().home.sendButton.default
    : sendingWritingImg;
  return (
    <div
      className="send-button h-100 d-flex align-items-end justify-content-end"
      onClick={(e) => {
        e.stopPropagation();
        props.onClick(e);
      }}
      onMouseEnter={() => setSendHover(true)}
      onMouseLeave={() => setSendHover(false)}
      role="button">
      {(() => {
        const mode_idle = props.active === false;
        const mode_writing = props.active && sendHover === false;
        const mode_writing_hover = props.active && sendHover === true;
        return (
          <div className="position-relative w-100">
            {sendHover && props.active ? (
              <img
                alt=""
                className="send-message-aux"
                src={sendMessageAux}
                style={{ bottom: props.activePosition }}
              />
            ) : null}
            <img
              alt=""
              className={mode_idle ? "ms-2 me-2 send-arrow" : "d-none"}
              src={sendingImg}
            />
            <img
              alt="sendButton"
              className={mode_writing ? "ms-2 me-2 send-arrow" : "d-none"}
              src={sendButton}
            />
            <img
              alt=""
              className={
                mode_writing_hover ? "ms-2 me-2 send-arrow-hover" : "d-none"
              }
              src={sendingHoverImg}
            />
          </div>
        );
      })()}
    </div>
  );
};
