import axios from "axios";

import { getLocalStorage, removeLocalStorage } from "@/utils/localstorage";

export const baseURL =
  process.env.REACT_APP_BASE_URL ?? "http://localhost:4000";

export const api = axios.create({
  baseURL,
  withCredentials: false,
});

api.interceptors.request.use((config) => {
  console.log("request");
  const token = getLocalStorage("token");
  config.headers["Authorization"] = `Bearer ${token}`;

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("response");
    if (error.response?.status === 401 || error.response?.status === 422) {
      removeLocalStorage("token");
      document.location.href = "/login";
    }

    return Promise.reject(error);
  },
);
