import chatbotTextLogo from "@/assets/img/chatbot_text.svg";
import chatbotTextImageLogo from "@/assets/img/chatbot_text_img.svg";
import CompaniesMainImage from "@/assets/img/companies-main-image.png";
import imgIcon from "@/assets/img/img_icon.png";
import Fonteide from "@/assets/img/mahou/image/agua_fonteide.png";
import BudWeiser from "@/assets/img/mahou/image/budwaiser.png";
import Mixta from "@/assets/img/mahou/image/cerveza_mixta.png";
import CervezasAlhambra from "@/assets/img/mahou/image/cervezas_alhambra.png";
import LaSalve from "@/assets/img/mahou/image/la_salve.png";
import SolandeCabras from "@/assets/img/mahou/image/solan_de_cabras.png";
import SilbyMainImage from "@/assets/img/silby-main-image.png";
import UserMainImage from "@/assets/img/users-main-image.png";

import FundadorHomeContent from "@/pages/Home/Content/Fundador";
import MahouHomeContent from "@/pages/Home/Content/Mahou";
import SecuritasHomeContent from "@/pages/Home/Content/Securitas";
import SilboHomeContent from "@/pages/Home/Content/Silbo";

import getMahouProductsByProfile from "@/branding/mahou/products";
import getSecuritaProductsByProfile from "@/branding/securitas/products";
import { fundadorTranslationEs } from "@/translations/fundador/es";
import { mahouTranslationEs } from "@/translations/mahou/es";
import { securitasTranslationEs } from "@/translations/securitas/es";
import { silboTranslationEs } from "@/translations/silbo/es";
import {
  IMAGES_TYPES,
  MAHOU_MODELS,
  MAHOU_PROFILES,
  SECURITAS_MODELS,
  SILBO_MODELS,
  SILBO_PROFILES,
} from "@/utils";

const theme = () => {
  const ico = () => {
    const SilboTranslations = "/silbo-favicon.ico";

    switch (process.env.REACT_APP_CLIENT) {
      case "Silbo":
        return SilboTranslations;
      case "Mahou":
        return "/mahou-favicon.ico";
      case "Securitas":
        return "/securitas-favicon.ico";
      case "Fundador":
        return "/fundador-favicon.ico";
      default:
        return SilboTranslations;
    }
  };

  const home = () => {
    const SilboHome = {
      mainImage: require("@/assets/img/home-main-image.svg"),
      homeAppsGroup: require("@/assets/img/home-apps-group.svg"),
      appLogo: require("@/assets/img/silbo_logo_v2.svg"),
      content: SilboHomeContent,
      sendButton: require("@/assets/img/sending_writing.svg"),
    };

    switch (process.env.REACT_APP_CLIENT) {
      case "Silbo":
        return SilboHome;
      case "Mahou":
        return {
          mainImage: require("@/assets/img/mahou-bottles.svg"),
          homeAppsGroup: undefined,
          appLogo: require("@/assets/img/mahou_logo.svg"),
          content: MahouHomeContent,
          loginLog: require("@/assets/img/Login_Logo_Mahou.svg"),
        };
      case "Securitas":
        return {
          mainImage: require("@/assets/img/securitas_main.svg"),
          homeAppsGroup: undefined,
          appLogo: require("@/assets/img/securitas_logo.svg"),
          content: SecuritasHomeContent,
          sendButton: require("@/assets/img/securitas/sending_writing.svg"),
        };
      case "Fundador":
        return {
          mainImage: require("@/assets/img/fundador_main.svg"),
          homeAppsGroup: undefined,
          appLogo: require("@/assets/img/fundador_logo.svg"),
          content: FundadorHomeContent,
          sendButton: require("@/assets/img/securitas/sending_writing.svg"),
        };
      default:
        return SilboHome;
    }
  };

  const login = () => {
    const SilboHome = {
      case: "Silbo",
      company: "Silbö",
      loginTitle: "Silbö GPT",
      loginLog: require("@/assets/img/silbo_logo_v2.svg"),
      loginImage: require("@/assets/img/silbo-login-image.png"),
    };

    switch (process.env.REACT_APP_CLIENT) {
      case "Silbo":
        return SilboHome;
      case "Mahou":
        return {
          case: "Mahou",
          company: "MAHOU SAN MIGUEL",
          loginLog: require("@/assets/img/Login_Logo_Mahou.svg"),
          loginImage: undefined,
        };
      case "Securitas":
        return {
          case: "Securitas",
          company: "Securitas Direct",
          loginTitle: "Securitas Direct GPT",
          loginLog: require("@/assets/img/Logo_sd.svg"),
          loginImage: require("@/assets/img/Login_Image.png"),
        };
      case "Fundador":
        return {
          case: "Fundador",
          company: "Fundador",
          loginTitle: "FUNDADOR GPT",
          loginLog: require("@/assets/img/login_fundador_logo.svg"),
        };
      default:
        return SilboHome;
    }
  };

  const translations = () => {
    const SilboTranslations = silboTranslationEs;

    switch (process.env.REACT_APP_CLIENT) {
      case "Silbo":
        return SilboTranslations;
      case "Mahou":
        return mahouTranslationEs;
      case "Securitas":
        return securitasTranslationEs;
      case "Fundador":
        return fundadorTranslationEs;
      default:
        return SilboTranslations;
    }
  };

  const appBarProfiles = () => {
    const silboAppBarProfiles = {
      profileList: [
        {
          name: "CopyWriter",
          descriptionT: "general_description",
          icon: chatbotTextLogo,
          path: `/${SILBO_PROFILES.SILBO_BRAND_SPECIALIST}`,
          // subItems: [
          //   {
          //     name: "GPT-4",
          //     descriptionT: "gpt_4_description",
          //     icon: starIcon,
          //     value: SILBO_MODELS.GPT4,
          //   },
          // ],
          value: SILBO_PROFILES.SILBO_BRAND_SPECIALIST,
        },
        {
          name: "Banco de imágenes",
          descriptionT: "silby_description",
          icon: imgIcon,
          path: "/images",
          subItems: [
            {
              name: "Silby",
              descriptionT: "stable_difusion_model_description",
              icon: undefined,
              path: "/images",
              type: IMAGES_TYPES.IMAGE,
              value: SILBO_MODELS.STABLE_DIFUSION,
            },
            {
              name: "Particulares",
              descriptionT: "users_model_description",
              icon: undefined,
              path: "/images",
              type: IMAGES_TYPES.IMAGE,
              value: SILBO_MODELS.USERS,
            },
            {
              name: "Empresas",
              descriptionT: "companies_model_description",
              icon: undefined,
              path: "/images",
              type: IMAGES_TYPES.IMAGE,
              value: SILBO_MODELS.COMPANIES,
            },
          ],
          value: SILBO_PROFILES.SILBO_IMAGES,
        },
        {
          name: "Ad Creator",
          descriptionT: "community_description",
          icon: chatbotTextImageLogo,
          path: `/${SILBO_PROFILES.SILBO_AD_CREATOR}`,
          value: SILBO_PROFILES.SILBO_AD_CREATOR,
        },
      ],
    };

    const mahouModels = [
      {
        name: "Fotografía Lifestyle",
        descriptionT: "companies_model_description",
        icon: undefined,
        path: "/images",
        type: IMAGES_TYPES.IMAGE,
        value: MAHOU_MODELS.LIFE_STYLE,
      },
      {
        name: "Fotografía Producto",
        descriptionT: "companies_model_description",
        icon: undefined,
        path: "/images",
        type: IMAGES_TYPES.PRODUCT,
        value: MAHOU_MODELS.PRODUCT_PHOTOGRAPHY,
      },
      {
        name: "Edición",
        descriptionT: "companies_model_description",
        icon: undefined,
        path: "/images",
        type: IMAGES_TYPES.EDITOR,
        value: MAHOU_MODELS.EDITOR,
      },
    ];

    const securitasModels = {
      profileList: [
        {
          name: "CopyWriter",
          descriptionT: "general_description",
          icon: chatbotTextLogo,
          path: `/${SILBO_PROFILES.SILBO_BRAND_SPECIALIST}`,
          value: SILBO_PROFILES.SILBO_BRAND_SPECIALIST,
        },
        {
          name: "Banco de imágenes",
          descriptionT: "companies_model_description",
          icon: imgIcon,
          path: "/images",
          value: SECURITAS_MODELS.ALTO_DETALLE,
          subItems: [
            {
              name: "Alto Detalle",
              descriptionT: "stable_difusion_model_description",
              icon: undefined,
              path: "/images",
              type: IMAGES_TYPES.PRODUCT,
              value: SECURITAS_MODELS.ALTO_DETALLE,
              products: getSecuritaProductsByProfile(
                SECURITAS_MODELS.ALTO_DETALLE,
              ),
            },
            {
              name: "Bodegón",
              descriptionT: "users_model_description",
              icon: undefined,
              path: "/images",
              type: IMAGES_TYPES.PRODUCT,
              value: SECURITAS_MODELS.BODEGON,
              products: getSecuritaProductsByProfile(SECURITAS_MODELS.BODEGON),
            },
          ],
        },
      ],
    };
    switch (process.env.REACT_APP_CLIENT) {
      case "Silbo":
        return silboAppBarProfiles;
      case "Mahou":
        return {
          profileList: [
            {
              name: "Cervezas Alhambra",
              descriptionT: "general_description",
              // icon: chatbotTextLogo,
              path: "/images",
              products: getMahouProductsByProfile(
                MAHOU_PROFILES.MAHOU_ALHAMBRA,
              ),
              subItems: mahouModels,
              value: MAHOU_PROFILES.MAHOU_ALHAMBRA,
            },
            {
              name: "Budweiser",
              descriptionT: "general_description",
              // icon: chatbotTextLogo,
              path: "/images",
              products: getMahouProductsByProfile(
                MAHOU_PROFILES.MAHOU_BUDWEISER,
              ),
              subItems: mahouModels,
              value: MAHOU_PROFILES.MAHOU_BUDWEISER,
            },
            {
              name: "Cervezas Mixta",
              descriptionT: "general_description",
              // icon: chatbotTextLogo,
              path: "/images",
              products: getMahouProductsByProfile(MAHOU_PROFILES.MAHOU_MIXTA),
              subItems: mahouModels,
              value: MAHOU_PROFILES.MAHOU_MIXTA,
            },
            {
              name: "Cervezas La Salve",
              descriptionT: "general_description",
              // icon: chatbotTextLogo,
              path: "/images",
              products: getMahouProductsByProfile(
                MAHOU_PROFILES.MAHOU_LA_SALVE,
              ),
              subItems: mahouModels,
              value: MAHOU_PROFILES.MAHOU_LA_SALVE,
            },
            {
              name: "Solán de Cabras",
              descriptionT: "general_description",
              // icon: chatbotTextLogo,
              path: "/images",
              products: getMahouProductsByProfile(
                MAHOU_PROFILES.MAHOU_SOLAN_DE_CABRAS,
              ),
              subItems: mahouModels,
              value: MAHOU_PROFILES.MAHOU_SOLAN_DE_CABRAS,
            },
            {
              name: "Fonteide",
              descriptionT: "general_description",
              // icon: chatbotTextLogo,
              path: "/images",
              products: getMahouProductsByProfile(
                MAHOU_PROFILES.MAHOU_FONTEIDE,
              ),
              subItems: mahouModels,
              value: MAHOU_PROFILES.MAHOU_FONTEIDE,
            },
          ],
        };
      case "Securitas":
        return securitasModels;
      default:
        return silboAppBarProfiles;
    }
  };

  const getImageContent = (model, profile) => {
    switch (model) {
      case SILBO_MODELS.STABLE_DIFUSION:
        return {
          title: "model_silby_title",
          image: SilbyMainImage,
        };
      case SILBO_MODELS.USERS:
        return {
          title: "model_users_title",
          image: UserMainImage,
        };
      case SILBO_MODELS.COMPANIES:
        return {
          title: "model_business_title",
          image: CompaniesMainImage,
        };
      case MAHOU_MODELS.LIFE_STYLE:
        switch (profile) {
          case MAHOU_PROFILES.MAHOU_ALHAMBRA:
            return {
              title: "profile_alhambra",
              image: CervezasAlhambra,
            };
          case MAHOU_PROFILES.MAHOU_BUDWEISER:
            return {
              title: "profile_budwaiser",
              image: BudWeiser,
            };
          case MAHOU_PROFILES.MAHOU_MIXTA:
            return {
              title: "profile_mixta",
              image: Mixta,
            };
          case MAHOU_PROFILES.MAHOU_LA_SALVE:
            return {
              title: "profile_la_salve",
              image: LaSalve,
            };
          case MAHOU_PROFILES.MAHOU_SOLAN_DE_CABRAS:
            return {
              title: "profile_solan_de_cabras",
              image: SolandeCabras,
            };
          case MAHOU_PROFILES.MAHOU_FONTEIDE:
            return {
              title: "profile_fonteide",
              image: Fonteide,
            };
          default:
            return {
              title: "model_business_title",
              image: CompaniesMainImage,
            };
        }
      default:
        return undefined;
    }
  };

  const initialState = () => {
    const silboInitialState = { model: SILBO_MODELS.STABLE_DIFUSION };

    switch (process.env.REACT_APP_CLIENT) {
      case "Silbo":
        return silboInitialState;
      case "Mahou":
        return { model: MAHOU_MODELS.LIFE_STYLE };
      case "Securitas":
        return { model: SECURITAS_MODELS.ALTO_DETALLE };
      default:
        return silboInitialState;
    }
  };

  const brandingImages = () => {
    const Silbo = {
      loadingV2: require("@/assets/img/silbo_loading_logo.gif"),
    };

    switch (process.env.REACT_APP_CLIENT) {
      case "Silbo":
        return Silbo;
      case "Mahou":
        return {
          loadingV2: require("@/assets/img/mahou_logo.svg"),
        };
      case "Securitas":
        return Silbo;
      default:
        return Silbo;
    }
  };

  return {
    translations: translations(),
    ico: ico(),
    appBarProfiles: appBarProfiles(),
    initialState: initialState(),
    login: login(),
    home: home(),
    image: {
      content: (model, profile) => getImageContent(model, profile),
      loadingImage: brandingImages(),
    },
  };
};
export default theme;

export const applyBrandingTheme = () => {
  if (
    !process.env.REACT_APP_CLIENT ||
    process.env.REACT_APP_CLIENT === "Silbo"
  ) {
    require("@/branding/silbo.css");
  } else if (process.env.REACT_APP_CLIENT === "Mahou") {
    require("@/branding/mahou.css");
  } else if (process.env.REACT_APP_CLIENT === "Securitas") {
    require("@/branding/securitas.css");
  } else if (process.env.REACT_APP_CLIENT === "Fundador") {
    console.log("requiere Fundador");
    require("@/branding/fundador.css");
  }
};
