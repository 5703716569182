import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

import promptLibraryCopyImg from "@/assets/img/prompt_library_copy.svg";
import promptLibraryCopyAppliedImg from "@/assets/img/prompt_library_copy_applied.svg";
import promptLibraryCopyHoverImg from "@/assets/img/prompt_library_copy_hover.svg";
import promptLibraryCopyHoverImgMahou from "@/assets/img/prompt_library_copy_hover_mahou.svg";

import securitasPrompts from "@/branding/securitas/prompt_library_data_es.json";
import theme from "@/branding/theme";
import { SILBO_MODELS } from "@/utils";

import "./PromptLibrary.css";
import imagePrompts from "./prompt_image_library_data_es";
import generalPrompts from "./prompt_library_data_es.json";

export const PromptManager = (props) => {
  const { t } = useTranslation();

  const promptLibrary =
    theme().login.case === "Securitas" ? securitasPrompts : generalPrompts;
  const model = useSelector((state) => state.main.model);
  const data = props.isForImages ? imagePrompts : promptLibrary;
  const [transitionStart, setTransitionStart] = useState(false);
  const [character, setCharacter] = useState(data[0].character);
  const [promptList, setPromptList] = useState(data[0].prompt_list);
  const [prompt, setPrompt] = useState(data[0].prompt_list[0]);
  const [promptApplied, setPromptApplied] = useState(false);
  const [promptApplyHover, setPromptApplyHover] = useState(false);

  const modalTitle = () => {
    if (props.isForImages) {
      switch (model) {
        case SILBO_MODELS.STABLE_DIFUSION:
          return "Silby Prompt Library";
        case SILBO_MODELS.USERS:
          return "Particulares Prompt Library";
        case SILBO_MODELS.COMPANIES:
          return "Empresas Prompt Library";
        default:
          return "Silby Prompt Library";
      }
    }
    return "Prompt Library";
  };

  useEffect(() => {
    setTransitionStart(true);
  }, []);

  useEffect(() => {
    if (props.isForImages) {
      switch (model) {
        case SILBO_MODELS.COMPANIES:
          return setCharacter(data[1].character);
        case SILBO_MODELS.USERS:
          return setCharacter(data[2].character);
        default:
          return setCharacter(data[0].character);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    const item = data.find((item) => item.character === character);
    setPromptList(item.prompt_list);
    setPrompt(item.prompt_list[0]);
    setPromptApplied(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [character]);

  const onSetKey = (key) => {
    const item = promptList.find((item) => item.key === key);
    setPrompt(item);
    setPromptApplied(false);
  };

  const onApplyPrompt = () => {
    navigator.clipboard.writeText(prompt.template);
    setPromptApplied(true);
  };

  return (
    <div className="silbo-prompt">
      <div className="overlay" />
      <div
        className="prompt-container d-flex justify-content-center"
        onClick={() => props.onClose()}>
        <div
          className={`prompt-content w-100 d-flex flex-column  ${transitionStart ? "show" : ""}`}
          onClick={(e) => e.stopPropagation()}>
          <div className="text-end pt-3 pe-3">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => props.onClose()}
              role="button"
            />
          </div>
          <div className="ps-3 pe-3 d-flex flex-column flex-grow-1">
            <h2 className="fw-bold">{modalTitle()}</h2>
            <hr />

            <div
              className="d-flex w-100 justify-content-between flex-grow-1 pb-3"
              style={{ height: "0px" }}>
              {!props.isForImages ? (
                <div
                  className="border-end h-100 character pe-2 ps-2 pt-2"
                  style={{ width: "30%", overflow: "auto" }}>
                  <div className="title mb-3 ms-3">{t("Category")}</div>
                  <div>
                    {data.map((item, idx) => (
                      <div
                        className={`mt-2 item ${character === item.character ? "active" : ""}`}
                        key={idx}
                        onClick={() => setCharacter(item.character)}
                        role="button">
                        {item.character}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              <div
                className="h-100 prompt pe-2 ps-2 pt-2"
                style={{
                  width: !props.isForImages ? "30%" : "100%",
                  overflow: "auto",
                  height: "0px",
                }}>
                <div className="title mb-3 ms-3">Prompt</div>
                {promptList.map((item, idx) => (
                  <div
                    className={`mt-2 item ${prompt.key === item.key ? "active" : ""}`}
                    key={idx}
                    onClick={() => onSetKey(item.key)}
                    role="button">
                    {item.key}
                  </div>
                ))}
              </div>
              <div
                className="ps-3 pe-3"
                style={{ width: !props.isForImages ? "40%" : "588px" }}>
                <div
                  className="pt-2 d-flex flex-column template"
                  style={{
                    minHeight: "100%",
                    height: 0,
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}>
                  <div className="title mb-3 ms-3">
                    {!props.isForImages ? "Template" : "Referencia"}
                  </div>
                  <div className=" d-flex flex-column flex-grow-1">
                    <div
                      className={
                        !props.isForImages
                          ? "p-3 m-3 template-content"
                          : "px-4 py-2 mx-4 my-3 template-content"
                      }
                      style={{
                        fontSize: props.isForImages ? "12px" : undefined,
                      }}>
                      {props.isForImages && prompt.image ? (
                        <img
                          alt={prompt.template}
                          className={!props.isForImages ? "p-3" : ""}
                          src={prompt.image}
                          style={{ height: "267px", padding: "1rem 0" }}
                        />
                      ) : null}
                      <p>
                        {prompt.template.length > 185
                          ? prompt.template.slice(0, 185) + "..."
                          : prompt.template}
                      </p>
                    </div>

                    <div className="d-flex p-3 m-3 justify-content-between align-items-center cta">
                      <div
                        className="fw-bold prompt-button"
                        onClick={() => props.onSelected(prompt.template)}
                        role="button">
                        {t("use_this_prompt")}
                      </div>
                      <div
                        onClick={() => onApplyPrompt()}
                        onMouseEnter={() => setPromptApplyHover(true)}
                        onMouseLeave={() => setPromptApplyHover(false)}
                        role="button">
                        {(() => {
                          const is_default =
                            promptApplied === false &&
                            promptApplyHover === false;
                          const is_applied = promptApplied;
                          const is_hover =
                            promptApplied === false && promptApplyHover;
                          return (
                            <>
                              <img
                                alt=""
                                className={`${is_default ? "" : "d-none"} images-button`}
                                src={promptLibraryCopyImg}
                              />
                              <img
                                alt=""
                                className={`${is_applied ? "" : "d-none"}`}
                                src={promptLibraryCopyAppliedImg}
                              />
                              <img
                                alt=""
                                className={`${is_hover ? "" : "d-none"}`}
                                src={
                                  theme().login.case === "Mahou"
                                    ? promptLibraryCopyHoverImgMahou
                                    : promptLibraryCopyHoverImg
                                }
                              />
                            </>
                          );
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
