import React, { useState } from "react";

import "./DropFile.css";

const DragAndDropFile = () => {
  const [dragging, setDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);

  const handleFileSelect = (event) => {
    console.log(event.target);
    console.log(event.target.files);
    if (event.target.files[0]) {
      const newFile = event.target.files;

      setSelectedFile(newFile[0]);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    console.log("e.target", e.target);
    console.log("e.dataTransfer.files", e.dataTransfer.files);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  return selectedFile ? (
    <div className="drop-file-selected-image">
      <h3>Modifica tu imagen:</h3>
      <img alt="Selected" src={URL.createObjectURL(selectedFile)} />
      <div className="drop-file-selected-container">
        <div className="drop-file-selected-input-group">
          <div className="d-flex align-center gap-2">
            <label for="arriba">Arriba</label>
            <div className="input-container">
              <input id="arriba" type="text" value="200" />
              <span>PX</span>
            </div>
          </div>
          <div className="d-flex align-center gap-2">
            <label for="derecha">Derecha</label>
            <div className="input-container">
              <input id="derecha" type="text" value="200" />
              <span>PX</span>
            </div>
          </div>
        </div>
        <div className="drop-file-selected-input-group">
          <div className="d-flex align-center gap-2">
            <label for="abajo">Abajo</label>
            <div className="input-container">
              <input id="abajo" type="text" value="200" />
              <span>PX</span>
            </div>
          </div>

          <div className="d-flex align-center gap-2">
            <label for="izquierda">Izquierda</label>
            <div className="input-container">
              <input id="izquierda" type="text" value="200" />
              <span>PX</span>
            </div>
          </div>
        </div>
        <button className="drop-file-selected-button" type="button">
          Generar
        </button>
      </div>
    </div>
  ) : (
    <div
      className={`drop-file-container ${dragging ? "dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}>
      <input
        accept="image/jpg, image/jpeg, image/png"
        // disabled={selectedFile}
        id="fileUpload"
        onChange={handleFileSelect}
        style={{ display: "none" }}
        type="file"
      />
      <label
        className="drop-file-container-label"
        htmlFor="fileUpload"
        style={{ cursor: "pointer" }}>
        <h5 className="plus">+</h5>
        <h5 className="drag-text">
          Drag & drop a video or image or click here to upload
        </h5>
        <div className="underline" />
        <p className="drag-alt">
          Puedes subir imágenes .png y .jpg con un peso máximo de 1 MB.
        </p>
      </label>
    </div>
  );
};

export default DragAndDropFile;
